<template>
  <div>
    <div class="operationControl">
      <div class="searchbox">
        <div title="课程名称" class="searchboxItem ci-full">
          <span class="itemLabel">课程名称:</span>
          <el-input
            v-model="searchName"
            type="text"
            clearable
            size="small"
            placeholder="请输入课程名称"
          />
        </div>
        <span title="培训类型" class="searchboxItem ci-full flexcc">
          <span class="itemLabel">培训类型:</span>
          <tree
            ref="tree"
            @eventBtn="childBack"
            :ruleForm="ruleForm"
            size="small"
            @clearParams="clearParams"
            modal
            typeStu
          />
        </span>
        <!-- <div title="资源提供者" class="searchboxItem ci-full">
          <span class="itemLabel">资源提供者:</span>
          <el-select
            size="small"
            v-model="compId"
            remote
            :remote-method="getCompanyList"
            filterable
            clearable
            placeholder="请至少输入两个字搜索"
          >
            <el-option
              v-for="item in CompanyList"
              :key="item.compId"
              :label="item.compName"
              :value="item.compId"
            ></el-option>
          </el-select>
        </div> -->
        <div title="本地推荐课程" class="searchboxItem ci-full">
          <el-checkbox class="sabicss" v-model="localRecommendLimit">本地推荐课程</el-checkbox>
        </div>
        <div class="df">
          <el-button type="primary" class="bgc-bv" round @click="getData()"
            >查询</el-button
          >
        </div>
      </div>
      <div class="df searchbox">
        <!-- <el-button type="primary" class="bgc-bv" round @click="doRoute">我要发布</el-button>  -->
      </div>
    </div>
    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :height="tableHeight"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          stripe
          :header-cell-style="tableHeader"
        >
          <el-table-column
            label="序号"
            align="center"
            type="index"
            fixed
            :index="indexMethod"
          />
          <el-table-column
            label="课程名称"
            width="360"
            align="left"
            prop="courseName"
            fixed
          />
          <el-table-column
            label="课时标准(分钟)"
            align="center"
            width="120"
            show-overflow-tooltip
            prop="convertClassHour"
            >
            <template slot-scope="scope">
              <span>{{ scope.row.convertClassHour || 45 }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="总课时"
            prop="classHour"
            show-overflow-tooltip
            align="right"
          />
          <el-table-column
            label="总学时"
            prop="lessonNum"
            show-overflow-tooltip
            align="right"
          />
          <el-table-column
            label="课件数量"
            prop="kpointTotalNum"
            show-overflow-tooltip
            align="right"
          />
          <el-table-column
            label="视频总时长"
            minWidth="120"
            prop="kpointTotalDuration"
            show-overflow-tooltip
            align="right"
          >
            <template slot-scope="scope">
              <span>{{ getTime(scope.row.kpointTotalDuration) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="培训类型"
            align="left"
            show-overflow-tooltip
            prop="trainTypeNamePath"
            width="120"
          />
          <el-table-column
            label="岗位类型"
            show-overflow-tooltip
            align="left"
            width="160"
          >
            <template slot-scope="scope">{{
              scope.row.postName || "--"
            }}</template>
          </el-table-column>
          <el-table-column
            label="行业类型"
            align="left"
            show-overflow-tooltip
            width="160"
          >
            <template slot-scope="scope">{{
              scope.row.industryNamePath || "--"
            }}</template>
          </el-table-column>
          <el-table-column
            label="职业/工种"
            show-overflow-tooltip
            align="left"
            width="200"
          >
            <template slot-scope="scope">{{
              scope.row.occupationNamePath || "--"
            }}</template>
          </el-table-column>
          <el-table-column label="培训等级" show-overflow-tooltip align="left">
            <template slot-scope="scope">{{
              scope.row.trainLevelName || "--"
            }}</template>
          </el-table-column>
          <el-table-column label="备注" show-overflow-tooltip align="left" width="200">
            <template slot-scope="scope">{{
                scope.row.remark || "--"
              }}</template>
          </el-table-column>
          <!-- <el-table-column
            label="资源提供者"
            prop="compName"
            show-overflow-tooltip
            align="left"
            width="200"
          /> -->
          <el-table-column
            label="操作"
            align="center"
            width="140px"
            fixed="right"
          >
            <div slot-scope="scope">
              <el-button
                type="text"
                style="padding: 0 5px"
                size="mini"
                @click="seeCourse(scope.row)"
                >详情</el-button
              >
              <el-button
                style="padding: 0 5px"
                :disabled="
                  scope.row.addState == 0 && scope.row.notState == 0
                    ? false
                    : true
                "
                type="text"
                size="mini"
                @click="salesState(scope.row.courseId)"
                >加入我的课程</el-button
              >
            </div>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import tree from "@/components/treePopup";
export default {
  name: "platform_Course",
  components: {
    Empty,
    PageNum,
    tree,
  },
  mixins: [List],
  data() {
    return {
      CompanyList: [], // 资源提供者
      activeName: "first",
      searchName: "",
      trainTypeId: "", // 培训类型
      compId: "", // 资源提供者
      localRecommendLimit: "", // 本地推荐课程
      props: {
        value: "id",
        label: "label",
        emitPath: false,
      },
      params: {},
      ruleForm: {
        Trainingtype: "",
      },
    };
  },
  watch: {
    compId: function (val) {
      if (val == "") {
        this.CompanyList = [];
      }
    },
  },
  created() {},
  computed: {},
  methods: {
    // 培训类型回调
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },

    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        resourceName: this.searchName || "",
        compId: this.compId || "",
        
      };
      if (this.localRecommendLimit) {
        params.localRecommendLimit = this.localRecommendLimit;
      }
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }
      this.doFetch({
        url: "/biz/course/queryPlatformCourse",
        params,
        pageNum,
      });
    },
    seeCourse(row) {
      this.$router.push({
        path: "/web/coursePages",
        query: {
          courseId: row.courseId,
          addState: row.addState,
          notState: row.notState,
        },
      });
    },
    // 资源提供者
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    // 加入
    salesState(courseId) {
      // 调 - 根据类型，机构id或区划id查询提示信息（加课和配课的时候用）
      this.$post("/course/useset/selectListByType", {
        courseId,
      })
        .then((res) => {
          if (res.status == 0) {
            if (res.data && res.data.tipcontent) {
              this.$confirm(res.data.tipcontent,'提示')
                .then((_) => {
                  // 加入
                  this.$post("/biz/course/insertPlatformCourseToMine", {
                    courseId: courseId,
                  })
                    .then((res) => {
                      if (res.status == 0) {
                        this.$message({
                          type: "success",
                          message: "已成功加入我的课程",
                        });
                        this.getData(-1);
                      }
                    })
                    .catch(() => {
                      return;
                    });
                })
                .catch((_) => {});
            } else {
              // 加入
              this.$post("/biz/course/insertPlatformCourseToMine", {
                courseId: courseId,
              })
                .then((res) => {
                  if (res.status == 0) {
                    this.$message({
                      type: "success",
                      message: "已成功加入我的课程",
                    });
                    this.getData(-1);
                  }
                })
                .catch(() => {
                  return;
                });
            }
          }
        })
        .catch(() => {
          return;
        });
    },
    doRoute() {
      this.$router.push({
        path: "/web/myresourseList",
        query: {
          active: "first",
        },
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 40 + 15;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    getTime(val) {
      if (!val) {
        return "0秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (
        (h != "00" ? h + "时" : "") +
          (m != "00" ? m + "分" : "") +
          (s != "00" ? s + "秒" : "") || ""
      );
    },
  },
};
</script>
<style lang="less">
.addlist {
  h3 {
    padding: 10px 0;
  }
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.zsBtn {
  margin-left: 20px;
  button {
    height: 40px;
  }
}
.certificate-box {
  > div:last-child {
    border: 0;
  }
}
.card-border {
  padding: 10px;
  border-bottom: 1px dashed #eee;
}
.sabicss .el-checkbox__inner{
  // 公共样式里写的，我服了
  // min-width: 5rem;
  // text-align: right;
  // font-size: 14px;
  // padding: 0 0.5rem;
  min-width: 0;
  padding: 0 ;
}
</style>
